import React, { lazy, Suspense } from "react";
import clsx from "clsx";
import makeStyles from "@material-ui/styles/makeStyles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import localDE from "../../locales/de.json";
import localEN from "../../locales/en.json";

const ReactLink = lazy(() =>
	import(/* webpackChunkName: "RreactLink" */ "@micado-digital/react-link/ReactLink")
);

const useStyles = makeStyles(theme => ({
	content: {
		border: "1px solid " + theme.palette?.grey?.[300],
		display: "flex",
		flexDirection: "column",
		padding: theme.spacing(3)
	},
	text: {
		color: theme.palette?.text?.primary
	},
	button: {
		display: "flex",
		justifyContent: "flex-end",
		marginTop: "auto",
		paddingTop: theme.spacing(2)
	}
}));

const SearchResultContent = ({ description, lang, pageName, title }) => {
	const css = useStyles();
	const l = useLocals({
		lang: lang,
		res: {
			de: localDE,
			en: localEN
		}
	});

	return (
		<Suspense fallback={<></>}>
			<ReactLink to={pageName}>
				<Box className="mco-singleteaser-01" mb={2}>
					<Box className={clsx("mco-singleteaser-01__content", css.content)}>
						<Typography
							className="mco-singleteaser-01__title"
							color="secondary"
							component="h3"
							dangerouslySetInnerHTML={{ __html: title }}
							variant="h4"
						/>
						<Box mt={1}>
							<Typography
								className={clsx(css.text, "mco-singleteaser-01__text")}
								dangerouslySetInnerHTML={{ __html: description }}
							/>
						</Box>
						<Box className={css.button}>
							<Suspense fallback={<></>}>
								<Button color="primary" fullWidth variant="contained">
									{l("search-result-content.button")}
								</Button>
							</Suspense>
						</Box>
					</Box>
				</Box>
			</ReactLink>
		</Suspense>
	);
};

export default SearchResultContent;
