function aroundMeFilterIcons() {
	return [
		{
			identifier: "Apotheken",
			url: "/img/elements/around-me/filter/apotheken.svg"
		},
		{
			identifier: "Pharmacy",
			url: "/img/elements/around-me/filter/apotheken.svg"
		},
		{
			identifier: "Restaurant",
			url: "/img/elements/around-me/filter/restaurant.svg"
		},
		{
			identifier: "Bergrestaurant",
			url: "/img/elements/around-me/filter/alm.svg"
		},
		{
			identifier: "Mountain restaurant",
			url: "/img/elements/around-me/filter/alm.svg"
		},
		{
			identifier: "Tourismusverband",
			url: "/img/elements/around-me/filter/tvb.svg"
		},
		{
			identifier: "Tourist office",
			url: "/img/elements/around-me/filter/tvb.svg"
		},
		{
			identifier: "Bergbahn",
			url: "/img/elements/around-me/filter/bergbahn.svg"
		},
		{
			identifier: "Cable car",
			url: "/img/elements/around-me/filter/bergbahn.svg"
		},
		{
			identifier: "Bar",
			url: "/img/elements/around-me/filter/bar.svg"
		},
		{
			identifier: "Bar",
			url: "/img/elements/around-me/filter/bar.svg"
		},
		{
			identifier: "Geschäfte",
			url: "/img/elements/around-me/filter/shop.svg"
		},
		{
			identifier: "Shops",
			url: "/img/elements/around-me/filter/shop.svg"
		},
		{
			identifier: "Schwimmbäder",
			url: "/img/elements/around-me/filter/schwimmbaeder.svg"
		},
		{
			identifier: "Swimming pools",
			url: "/img/elements/around-me/filter/schwimmbaeder.svg"
		},
		{
			identifier: "Bushaltestelle",
			url: "/img/elements/around-me/filter/bus.svg"
		},
		{
			identifier: "Bus stop",
			url: "/img/elements/around-me/filter/bus.svg"
		},
		{
			identifier: "Modegeschäfte",
			url: "/img/elements/around-me/filter/mode.svg"
		},
		{
			identifier: "Fashion stores",
			url: "/img/elements/around-me/filter/mode.svg"
		},
		{
			identifier: "Bergerlebniswelt",
			url: "/img/elements/around-me/filter/mountain.svg"
		},
		{
			identifier: "Mountain adventure worlds",
			url: "/img/elements/around-me/filter/mountain.svg"
		}
	];
}

export default aroundMeFilterIcons;
