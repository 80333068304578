import React, { lazy, Suspense, useContext, useEffect } from "react";
import { useScrollYPosition } from "react-use-scroll-position";
import PropTypes from "prop-types";
import clsx from "clsx";
import makeStyles from "@material-ui/styles/makeStyles";
import useTheme from "@material-ui/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MenuContext from "../../../context/menuContext";
import Typography from "@material-ui/core/Typography";
import useSystemLinks from "@micado-digital/react-ui/hooks/useSystemLinks";

const Booking = lazy(() => import(/* webpackChunkName: "reactBooking" */ "./booking"));
const Request = lazy(() => import(/* webpackChunkName: "reactRequest" */ "./request"));
const Logo = lazy(() => import(/* webpackChunkName: "reactLogo" */ "./logo"));
const Language = lazy(() => import(/* webpackChunkName: "reactLanguage" */ "./language"));
const Mobile = lazy(() => import(/* webpackChunkName: "reactMobile" */ "./mobile"));
const Desktop = lazy(() => import(/* webpackChunkName: "reactDesktop" */ "./desktop"));
const Hamburger = lazy(() => import(/* webpackChunkName: "reactHamburger" */ "./hamburger"));
const Search = lazy(() => import(/* webpackChunkName: "reactSearch" */ "./search"));

const useStyles = makeStyles(theme => ({
	root: {
		background: theme?.palette?.background?.header?.dark,
		alignItems: "center",
		display: "grid",
		height: "100%",
		gridTemplateColumns: "32px auto auto",
		left: 0,
		maxHeight: "calc(env(safe-area-inset-top) + 80px)",
		padding:
			"env(safe-area-inset-top) " + theme?.spacing(2) + "px 0 " + theme?.spacing(2) + "px",
		position: "fixed",
		right: 0,
		top: 0,
		transition: theme.transitions.create(["background"], {
			duration: theme.transitions.duration.complex
		}),
		zIndex: theme?.zIndex?.appBar,
		[theme.breakpoints.up("sm")]: {
			paddingLeft: theme?.spacing(3),
			paddingRight: theme?.spacing(3)
		},
		[theme.breakpoints.up("lg")]: {
			gridTemplateColumns: "338px auto 390px",
			maxHeight: "calc(env(safe-area-inset-top) + 100px)"
		},
		[theme.breakpoints.up("xl")]: {
			padding:
				"env(safe-area-inset-top) " +
				theme?.spacing(8.75) +
				"px 0 " +
				theme?.spacing(8.75) +
				"px"
		}
	},
	intro: {
		background: "none",
		[theme.breakpoints.up("xl")]: {
			transform: "translate(0, 32px)",
			transition: theme.transitions.create(["transform"], {
				duration: theme.transitions.duration.complex
			})
		}
	},
	fullscreen: {
		background: "none",
		[theme.breakpoints.up("xl")]: {
			transform: "translate(0, 32px)",
			transition: theme.transitions.create(["transform"], {
				duration: theme.transitions.duration.complex
			})
		}
	},
	fixedHeader: {
		background: theme?.palette?.background?.header?.dark,
		boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.24)",
		[theme.breakpoints.up("xl")]: {
			transform: "translate(0, 0)"
		}
	},
	hamburger: {
		margin: theme?.spacing(0, 1, 0, -1.5),
		order: 1
	},
	logo: {
		order: 2,
		margin: "0 auto 0 16px",
		[theme.breakpoints.up("md")]: {
			margin: "0 auto 0 24px"
		},
		[theme.breakpoints.up("lg")]: {
			margin: "0 auto"
		}
	},
	buttons: {
		alignItems: "center",
		display: "flex",
		justifyContent: "flex-end",
		marginLeft: "auto",
		order: 3,
		"& .mco-header__language": {
			display: "none",
			marginRight: theme.spacing(1),
			[theme.breakpoints.up("sm")]: {
				display: "flex"
			}
		},
		"& .mco-header__language p": {
			color: theme.palette.secondary.main,
			fontFamily: "'Marat-Sans-Semibold'",
			fontSize: 16,
			fontWeight: 600,
			lineHeight: 1,
			marginTop: 1,
			[theme.breakpoints.up("lg")]: {
				fontSize: 23
			}
		},
		"& .mco-header__request": {
			marginRight: theme.spacing(2)
		}
	},
	buttonsHeaderFixed: {
		"& .mco-header__language p": {
			color: "#FFFFFF"
		}
	},
	title: {
		display: "none",
		order: 2,
		marginLeft: theme.spacing(3),
		[theme.breakpoints.up("md")]: {
			display: "flex",
			"& p": {
				fontSize: 25,
				maxWidth: 520,
				overflow: "hidden",
				textOverflow: "ellipsis",
				whiteSpace: "nowrap"
			}
		},
		[theme.breakpoints.up("lg")]: {
			margin: "0 auto"
		},
		"@media (min-width: 1440px)": {
			"& p": {
				fontSize: 30,
				maxWidth: 700
			}
		},
		[theme.breakpoints.up("xl")]: {
			margin: "0 auto",
			"& p": {
				fontSize: 35,
				maxWidth: 900
			}
		}
	},
	hideLogo: {
		opacity: 0,
		pointerEvents: "none"
	},
	removeLogo: {
		display: "none"
	},
	logoFullscreen: {
		[theme.breakpoints.up("lg")]: {
			"& .mco-header__logo-link": {
				maxWidth: 300,
				left: "50%",
				opacity: 1,
				position: "absolute",
				transform: "translate(-50%, 10px)"
			}
		}
	},
	hideLogoFullscreen: {
		"& .mco-header__logo-link": {
			transform: "translate(0, 0)"
		}
	},
	menuOpen: {
		"&.mco-header": {
			background: theme?.palette?.background?.header?.dark,
			transform: "translate(0, 0)",
			transition: "background 500ms ease 2s",
			display: "flex"
		},
		"& .mco-header__hamburger": {
			marginRight: theme.spacing(1),
			order: 1,
			[theme.breakpoints.up("md")]: {
				marginRight: theme.spacing(4)
			}
		},
		"& .mco-header__search": {
			order: 2,
			[theme.breakpoints.up("md")]: {
				marginLeft: 136
			}
		},
		"& .mco-header__language": {
			marginLeft: theme.spacing(0.5),
			order: 3,
			[theme.breakpoints.up("md")]: {
				marginLeft: "auto",
				"& p": {
					fontFamily: "'Marat-Sans-Semibold'",
					fontSize: 23,
					fontWeight: 600,
					lineHeight: 1,
					marginTop: 1
				}
			}
		},
		"& .mco-header__buttons": {
			[theme.breakpoints.up("md")]: {
				marginLeft: theme.spacing(2)
			}
		},
		"& .mco-header__request": {
			display: "flex",
			marginLeft: "auto",
			order: 4,
			[theme.breakpoints.up("md")]: {
				marginRight: "auto"
			}
		},
		"& .mco-header__booking": {
			marginLeft: theme.spacing(2),
			order: 5
		},
		"& .mco-header__logo": {
			display: "none",
			[theme.breakpoints.up("md")]: {
				display: "flex",
				marginLeft: theme.spacing(0.5),
				opacity: 1,
				"& a": {
					width: 190,
					"& img": {
						width: "100%"
					}
				}
			},
			[theme.breakpoints.up("xl")]: {
				"& a": {
					width: 250
				}
			}
		}
	}
}));

const Header = ({ fixed, lang, mainmenu, metamenu, mode, variant, title }) => {
	const theme = useTheme();
	const css = useStyles();
	const [menuOpen, setMenuOpen] = useContext(MenuContext);
	const smallScreen = useMediaQuery(theme.breakpoints.up(350));
	const screenMD = useMediaQuery(theme.breakpoints.up("md"));
	const screenLG = useMediaQuery(theme.breakpoints.up("lg"));
	const sysLink = useSystemLinks();

	const scrollY = useScrollYPosition();
	const fixedHeader = fixed ? false : scrollY > 100;

	useEffect(() => {
		if (menuOpen) {
			document.body.classList.add("submenu--open");
		} else {
			document.body.classList.remove("submenu--open");
		}
	}, [menuOpen]);

	useEffect(() => {
		if (fixedHeader) {
			document.body.classList.add("header--fixed");
		} else {
			document.body.classList.remove("header--fixed");
		}
	}, [fixedHeader]);

	const mobileMenu = menuOpen && (
		<Suspense fallback={<></>}>
			<Mobile
				isOpen={menuOpen}
				mainmenu={mainmenu}
				metamenu={metamenu}
				icon="/img/icons/menu-dropdown.svg"
			/>
		</Suspense>
	);

	const desktopMenu = (
		<Suspense fallback={<></>}>
			<Desktop
				isOpen={menuOpen}
				mainmenu={mainmenu}
				metamenu={metamenu}
				icon="/img/icons/menu-dropdown.svg"
			/>
		</Suspense>
	);

	return (
		<header
			className={clsx("mco-header", css.root, {
				[css.fixedHeader]: fixedHeader,
				[css.fixedHeader]: menuOpen ? false : fixedHeader,
				[css.intro]: variant === "intro",
				[css.fullscreen]: variant === "fullscreen",
				[css.menuOpen]: menuOpen
			})}
		>
			{menuOpen && (screenMD ? desktopMenu : mobileMenu)}
			<div className={clsx("mco-header__hamburger", css.hamburger)}>
				<Suspense fallback={<></>}>
					<Hamburger isOpen={menuOpen} setOpen={setMenuOpen} />
				</Suspense>
			</div>
			<div
				className={clsx("mco-header__logo", css.logo, {
					[css.hideLogo]: variant === "intro" && !fixedHeader,
					[css.removeLogo]: title && fixedHeader && screenMD,
					[css.logoFullscreen]: variant === "fullscreen" && !fixedHeader && !menuOpen,
					[css.hideLogoFullscreen]: variant === "fullscreen" && fixedHeader
				})}
			>
				{variant === "fullscreen" ? (
					<Suspense fallback={<></>}>
						{!smallScreen ? (
							<Logo file={"/img/logo-small.svg"} height={30} width={34} />
						) : fixedHeader && screenLG ? (
							<Logo file={"/img/logo-fixed.svg"} height={50} width={250} />
						) : screenLG && !menuOpen ? (
							<Logo file={"/img/logo-fullscreen.svg"} height={139} width={298} />
						) : (
							<Logo file={"/img/logo-fixed.svg"} height={50} width={150} />
						)}
					</Suspense>
				) : (
					<Suspense fallback={<></>}>
						{!smallScreen ? (
							<Logo file={"/img/logo-small.svg"} height={30} width={34} />
						) : screenLG ? (
							<Logo file={"/img/logo-fixed.svg"} height={50} width={250} />
						) : (
							<Logo file={"/img/logo-fixed.svg"} height={50} width={150} />
						)}
					</Suspense>
				)}
			</div>
			{title && fixedHeader && (
				<div className={clsx("mco-header__title", css.title)}>
					<Typography
						color="textSecondary"
						component="p"
						variant="h5"
						dangerouslySetInnerHTML={{ __html: title }}
					/>
				</div>
			)}
			{menuOpen && (
				<Suspense fallback={<></>}>
					<Search lang={lang} />
					<Language lang={lang} />
				</Suspense>
			)}
			<div
				className={clsx("mco-header__buttons", css.buttons, {
					[css.buttonsHeaderFixed]: fixedHeader
				})}
			>
				{!menuOpen && (
					<Suspense fallback={<></>}>
						<Language lang={lang} />
					</Suspense>
				)}
				<Suspense fallback={<></>}>
					<Request fixed={fixedHeader} lang={lang} menuOpen={menuOpen} />
				</Suspense>
				<Suspense fallback={<></>}>
					<Booking
						fixed={fixedHeader}
						lang={lang}
						link={sysLink("booking")}
						menuOpen={menuOpen}
						mode={mode}
						target="_blank"
					/>
				</Suspense>
			</div>
		</header>
	);
};

Header.propTypes = {
	fixed: PropTypes.bool,
	mainmenu: PropTypes.object.isRequired,
	metamenu: PropTypes.object
};

export default Header;
